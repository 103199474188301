<template>
  <vx-card :title="title">
    <vs-prompt
      title="XML"
      color="success"
      :active.sync="activePrompt"
      class="modal-bg"
    >
      <!-- @close="close" -->
      <!-- buttons-hidden="true" -->
      <div
        class="modal-content"
        :style="{ maxHeight: modalHeight + 'px', maxWidth: modalWeight + '%' }"
      >
        <pre>
        <code>{{ selectedRow | formatXml }}</code>
      </pre>
      </div>
    </vs-prompt>

    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="tblServer.data"
          :max-items="tblServer.length"
          :total="tblServer.total"
          @search="handleSearch"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.tblServer.start }} - {{ this.tblServer.end }} of
                  {{ this.tblServer.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in tblServer.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="5%"
              ><vs-checkbox
                :checked="checkAll"
                @input="onInputCheck(-1)"
              ></vs-checkbox
            ></vs-th>
            <vs-th>SO Date</vs-th>
            <vs-th>PO Ref No</vs-th>
            <vs-th>PO Ref Child No</vs-th>
            <vs-th>SAP Created-at</vs-th>
            <vs-th>SAP-Code</vs-th>
            <vs-th>Territory</vs-th>
            <vs-th>Salesman</vs-th>
            <vs-th>Customer</vs-th>
            <vs-th>Ship To</vs-th>
            <vs-th>Distribution Channel</vs-th>
            <vs-th>Incoterms</vs-th>
            <vs-th>Amount</vs-th>
            <vs-th>Order Status</vs-th>
            <vs-th>Order Category</vs-th>
            <vs-th>Order Type</vs-th>
            <vs-th>Payment Term</vs-th>
            <vs-th>RDD (Request Delivery Date)</vs-th>
            <vs-th>Order Note</vs-th>
            <vs-th>Error Message</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <!-- <feather-icon
                  title="Edit"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="handleEdit(data[indextr].id)"
                /> -->
                <feather-icon
                  title="Detail"
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="handleDetail(data[indextr])"
                />
              </vs-td>
              <!-- <vs-td>
                <div class="flex items-center">
                  <span class="mr-2">
                    {{ data[indextr].uuid | dataToShow }}
                  </span>
                  <vs-icon
                    class="vs-btn-primary"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    :icon="
                      copied === null || copied !== data[indextr].uuid
                        ? 'icon-copy'
                        : 'icon-check'
                    "
                    @click.stop="copyData(data[indextr].uuid)"
                  >
                    <vs-icon name="copy" />
                  </vs-icon></div
              ></vs-td> -->
              <!-- <vs-td>{{ data[indextr].date_time | formatDate }}</vs-td> -->
              <vs-td>{{ data[indextr].date | formatDate }}</vs-td> <!-- diubah, order date reference by SO date, bukan data creation date -->
              <vs-td>
                {{ data[indextr].ex_code }}
              </vs-td>
              <vs-td>
                <span>{{ data[indextr].code }}</span>
                <vs-icon
                  v-if="data[indextr].code"
                  class="vs-btn-primary"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  :icon="
                    copied === null || copied !== data[indextr].code
                      ? 'icon-copy'
                      : 'icon-check'
                  "
                  @click.stop="copyData(data[indextr].code)"
                >
                  <vs-icon name="copy" />
                </vs-icon>
              </vs-td>
              <vs-td>
                <span
                  v-if="
                    data[indextr].sap_created_at != '' &&
                    data[indextr].sap_created_at != null
                  "
                  >{{ data[indextr].sap_created_at | formatDate }}</span
                >
                <span v-else> - </span>
              </vs-td>
              <vs-td>
                <div class="flex items-center">
                  <vs-button
                    v-if="data[indextr].sap_code === ''"
                    class="m-1 p-1"
                    type="border"
                    color="primary"
                    @click="btnSyncrn(data[indextr].uuid)"
                    >ReSync</vs-button
                  >
                  <vs-button
                    v-show="data[indextr].IsXmlExist === true"
                    class="m-1 p-1"
                    type="gradient"
                    color="primary"
                    @click="openConfirm(data[indextr].xml_data.xmlData)"
                    >xml</vs-button
                  >
                </div>

                <div v-if="data[indextr].sap_code" class="flex items-center">
                  <span class="mr-2">
                    {{ data[indextr].sap_code }}
                  </span>
                  <vs-icon
                    v-if="data[indextr].sap_code"
                    class="vs-btn-primary"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    :icon="
                      copied === null || copied !== data[indextr].sap_code
                        ? 'icon-copy'
                        : 'icon-check'
                    "
                    @click.stop="copyData(data[indextr].sap_code)"
                  >
                    <vs-icon name="copy" />
                  </vs-icon>
                </div>
              </vs-td>
              <vs-td>
                Code : {{ data[indextr].territory_code }}
                <br />
                Name : {{ data[indextr].territory_name }}
              </vs-td>
              <vs-td>
                Code : {{ data[indextr].personal_code_external }}<br />
                Name :{{ data[indextr].personal_name }}
              </vs-td>
              <vs-td v-if="data[indextr].is_onetime">
                Code : <b>{{ data[indextr].customer_code }}</b>
                <br />
                Name : <b>{{ data[indextr].customer_name }}</b>
              </vs-td>
              <vs-td v-else>
                Code : {{ data[indextr].customer_code }}
                <br />
                Name : {{ data[indextr].customer_name }}
              </vs-td>
              <vs-td>
                ship to code : {{ data[indextr].ship_to_code }}
                <br />
                ship to name : {{ data[indextr].ship_to_name }}
              </vs-td>
              <vs-td>{{ data[indextr].distribution_channel }}</vs-td>
              <vs-td>{{ data[indextr].delivery_type }}</vs-td>
              <vs-td>
                total : {{ data[indextr].total | formatCurrency }}
                <br />
                outstanding : {{ data[indextr].outstanding | formatCurrency }}
              </vs-td>
              <vs-td>
                {{ data[indextr].text_status }}
              </vs-td>
              <vs-td>
                {{ data[indextr].category }}
              </vs-td>
              <vs-td>
                {{ data[indextr].type }}
              </vs-td>
              <vs-td>
                {{ data[indextr].top_name }}
              </vs-td>
              <vs-td>
                {{ data[indextr].reques_delivery_date | formatDate }}
              </vs-td>
              <vs-td>
                {{ data[indextr].note }}
              </vs-td>
              <vs-td>
                {{ data[indextr].message }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="tblServer.data.length > 0 ? tblServer.totalPage : 0"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>
<script>
import DetailComponent from "./detail";
import moment from "moment";
import xmlFormat from "xml-formatter";

export default {
  components: {
    DetailComponent,
  },
  props: ["tblServer"],
  watch: {
    selectedTerritory() {
      this.getPersonal();
    },
  },
  data() {
    return {
      title: "Auto PO",
      baseUrl: "/api/sfa/v1/customer-order",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      deleteId: null,
      id: null,
      tableDetails: {},
      selectedRow: null,
      copied: null,
      activePrompt: false,
      selectedXml: null,
      modalHeight: 300,
      modalWeight: 100,
    };
  },
  mounted() {
    // const contentHeight = this.$refs.content.clientHeight;
    // const wrapperHeight = this.$refs.wrapper.clientHeight;
    // if (contentHeight > wrapperHeight) {
    //   this.modalHeight = wrapperHeight - 50;
    // }
  },
  methods: {
    handleSearch(searching) {
      // this.table.search = searching;
      // this.table.page = 1;
      // this.getData();
      this.$emit("tblSearch", searching);
    },
    handleChangePage(page) {
      // this.table.page = page;
      // this.getData();
      this.$emit("tblChangePage", page);
    },
    handleSort(key, active) {
      // this.table.order = key;
      // this.table.sort = active;
      this.$emit("tblSort", key, active);
      // this.getData();
    },
    handleChangelength(val) {
      // this.table.length = val == "All" ? this.table.total : val;
      // this.table.page = 1;
      this.$emit("tblChangeLength", val);
      // this.getData();
    },
    handleClose() {
      // this.getData();
      this.detail = false;
    },
    // handleEdit(id) {
    //   this.$router.push({
    //     name: "customer-order-edit",
    //     params: { id: id },
    //   });
    // },
    btnSyncrn(uuid) {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/ocr/sap/send", {
          params: {
            uuid: uuid,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data on processing",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleDetail(co) {
      console.log(co)
      // push to router detail
      // Trigger change handler
      // this.$router.push({
      //   name: "customer-order-view",
      //   params: { id: co.id },
      // });
      this.$emit("handleDetail", co);
    },
    copyData(data) {
      const el = document.createElement("textarea");
      el.value = data;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.copied = data;
    },
    openConfirm(data) {
      this.activePrompt = true;
      this.selectedRow = data;
    },
  },

  filters: {
    formatDate(value) {
      const sortMonth = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let date = "",
        y = "",
        M = "",
        d = "",
        h = "",
        m = "",
        dateFormat = "";
      if (value) {
        if (value.length == 20) {
          var temp = "";
          for (let i = 0; i < value.length - 1; i++) {
            temp += value[i];
          }
          temp += "+0700";
          console.log(temp);
          // moment.tz.setDefault("Asia/Jakarta");
          // var date = moment(temp).format("DD MMM YYYY hh:mm");
          date = new Date(temp);
          y = date.getFullYear();
          M = sortMonth[date.getMonth()];
          d = date.getDate();
          h = date.getHours();
          m = date.getMinutes();
          dateFormat = `${d} ${M} ${y} ${h}:${m}`;
        }
        if (dateFormat == "") {
          dateFormat = "invalid date";
        }
        return dateFormat;
      }
    },
    formatStatus(value) {
      if (value == 0) {
        return "Open";
      } else {
        return "Close";
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    dataToShow(value) {
      if (value.length < 10) {
        return `${value.slice(0, 9)}`;
      } else {
        return `${value.slice(0, 5)}...${value.slice(-5)}`;
      }
    },
    // formatXml(xmlString) {
    //   let xml = xmlString.replace(/\n|\r/g, "");
    //   xml = xml.replace(/>\s*</g, ">\n<");
    //   // const parser = new DOMParser();
    //   // const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    //   // const serializer = new XMLSerializer();
    //   // let xml = serializer.serializeToString(xmlDoc);
    //   return xml.trim();
    // },
    formatXml(xmlString) {
      // const parser = new DOMParser();
      // const xmlDoc = parser.parseFromString(xmlString, "application/xml");
      // const serializer = new XMLSerializer();
      // let xml = serializer.serializeToString(xmlDoc);
      // xml = xml.replace(/>\s*</g, ">\n<");
      // // xml = xml.replace(/\n|\r/g, "");
      // xml = xml.replace(/<([^/>]+)\/>/g, "<$1></$1>");
      // return xml.trim();
      // const parser = new DOMParser();
      // const xmlDoc = parser.parseFromString(xmlString, "application/xml");
      // var xsltDoc = new DOMParser().parseFromString(
      //   [
      //     // describes how we want to modify the XML - indent everything
      //     '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      //     '      <xsl:strip-space elements="*"/>',
      //     '      <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      //     '           <xsl:value-of select="normalize-space(.)"/>',
      //     "      </xsl:template>",
      //     '      <xsl:template match="node()|@*">',
      //     '           <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      //     "      </xsl:template>",
      //     '      <xsl:output indent="yes"/>',
      //     "</xsl:stylesheet>",
      //   ].join("\n"),
      //   "application/xml"
      // );
      // var xsltProcessor = new XSLTProcessor();
      // xsltProcessor.importStylesheet(xsltDoc);
      // var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
      // var resultXml = new XMLSerializer().serializeToString(resultDoc);
      // resultXml = resultXml.replace(/<([^/>]+)\/>/g, "<$1></$1>");
      // return resultXml;
      console.log(xmlString);
      if (xmlString) {
        var xmlFormat1 = xmlFormat(xmlString, {
          indentation: "\t",
          filter: (node) => node.type !== "Comment",
          collapseContent: true,
          lineSeparator: "\n",
        });
        return xmlFormat1;
      } else {
        return "";
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.modal-content {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: rgb(243, 237, 237);
  overflow-y: auto;
  border-radius: 5px;
}
.modal-bg {
  position: fixed;
  max-width: 100%;
  size: 100%;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  /* backdrop-filter: blur(5px); efek blur */
  z-index: 999;
}
.con-vs-dialog .vs-dialog {
  max-width: 500px !important;
}
</style>
